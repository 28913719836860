import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import classNames from 'classnames';

import { GatsbyImage } from 'gatsby-plugin-image';
import useViewportSizes from 'use-viewport-sizes';

import Meta from './Meta';
import Header from './Header';

import { SiteContextProvider } from './SiteContext';
import theme, { media } from './theme';
import GlobalStyle from './GlobalStyle';

import BgPattern from './BgPattern';

const Wrapper = props => {
  console.log(props);
  const { home = false, children, className, data, location } = props;
  // console.log(props);
  // const background = useStaticQuery(graphql`
  //   {
  //     file(relativePath: { eq: "chevron_pattern.png" }) {
  //       id
  //       childImageSharp {
  //         gatsbyImageData(quality: 50)
  //       }
  //     }
  //   }
  // `);

  const [, height, updateVpSizes] = useViewportSizes();
  useEffect(() => {
    if (!height) {
      updateVpSizes();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SiteContextProvider home={home}>
        <SiteWrapper height={height} className={classNames('site-wrapper', className)}>
          <BgPattern />
          <Meta {...data?.sanityPage} {...data?.sanityHome} home={home} location={location} />
          <PageContainer className="page-container">
            <Header />
            {children}
          </PageContainer>
          <GlobalStyle />
        </SiteWrapper>
      </SiteContextProvider>
    </ThemeProvider>
  );
};

const PageContainer = styled.div`
  width: 800px;
  max-width: 100%;
  max-height: 95%;
  max-height: calc(100% - 2rem);
  margin-left: auto;
  margin-right: auto;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  box-shadow: ${({ theme }) => theme.shadow};
  overflow: scroll;
  /* display: none; */
  ${media.break`
    /* top: 50%;
    transform: translateY(-50%); */
  `}
`;

const SiteWrapper = styled.div`
  height: 100vh;
  ${({ height }) => height && `height: ${height}px`};
  position: relative;
  /* padding: 1rem; */

  background: linear-gradient(to bottom, #1f5e87 0%, #1d839c 54%, #148279 100%);
  overflow: hidden;
`;

const BackgroundImage = styled(GatsbyImage)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export default Wrapper;
