import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import ButtonStyles from './ButtonStyles';
import useSiteContext from './SiteContext';
import classNames from 'classnames';

const NavItem = ({ label, longLabel, externalUrl, link, calloutStyles: callout }) => {
  const { home } = useSiteContext();
  if (externalUrl) {
    return (
      <StyledAnchor
        className={classNames('external-link', { callout })}
        href={externalUrl}
        target="_blank"
        rel="noopener noreferrer"
        home={home ? home : null}
      >
        {label}
      </StyledAnchor>
    );
  }
  if (link) {
    return (
      <StyledLink className={classNames('page-StyledLink', { callout })} to={`/${link.slug.current}`} home={home ? home : null}>
        {home ? longLabel : label}
      </StyledLink>
    );
  }
  return (
    <StyledAnchor className="no-link" home={home ? home : null}>
      {label}
    </StyledAnchor>
  );
};

const StyledLink = styled(Link)`
  ${ButtonStyles}
  flex: 0 0 50%;

  display: inline-block;
  &.callout {
    background: ${({ theme }) => theme.red};
  }
`;

const StyledAnchor = styled.a`
  ${ButtonStyles}
  flex: 0 0 50%;

  display: inline-block;
  &.callout {
    background: ${({ theme }) => theme.red};
  }
`;

export default NavItem;
