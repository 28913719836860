import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import NavItem from './NavItem';
import { media } from './theme';

const Nav = () => {
  const {
    sanitySiteSettings: { mainMenu },
  } = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        mainMenu {
          _key
          externalUrl
          label
          longLabel
          calloutStyles
          link {
            title
            slug {
              current
            }
          }
        }
      }
    }
  `);
  console.log(mainMenu);

  return (
    <StyledNav>
      {mainMenu.map(item => (
        <NavItem key={item._key} {...item} />
      ))}
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  padding: 1rem;
  ${media.break`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-rows: auto;
    column-gap: 2rem;
  `}
`;
export default Nav;
