import { css } from 'styled-components';
import { media } from './theme';

const ButtonStyles = css`
  display: block;
  background: ${({ theme }) => theme.navy};
  color: white;
  /* margin-bottom: 1rem; */
  margin: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.font.condensed};
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.font.extrabold};
  letter-spacing: 1px;
  text-align: center;
  padding: 1rem 0.5rem;
  border-radius: 10rem;
  flex-grow: 1;
  ${media.break`
  padding: 1.25rem .5rem;
  margin: 1rem 1.5rem;
  margin: ${({ home }) => (home ? '1rem 1.5rem' : '1rem')};
`}
`;

export default ButtonStyles;
