import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

import Nav from './Nav';
import { media } from './theme';
import useSiteContext from './SiteContext';

const Header = () => {
  const logo = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "BCOP-logo.png" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 650)
        }
      }
    }
  `);
  // console.log(home);
  const { home } = useSiteContext();

  const logoImage = getImage(logo.file);

  return (
    <SiteHeader className={classNames(`header${home && '--home'}`)} home={home ? home : null}>
      <Link to="/" className="logo-wrapper">
        <HomeLogo
          className="home-logo"
          image={logoImage}
          alt="Redefining Quality: A New Vision for Success in Person-Centered Contraceptive Access Projects"
        />
      </Link>
      {!home && <Nav />}
    </SiteHeader>
  );
};

const SiteHeader = styled.header`
  .logo-wrapper {
    padding: 1rem 3rem;
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    ${({ home }) =>
      home &&
      `
      width: 650px;
      `}
  }
  ${media.break`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .logo-wrapper {
      flex: 0 40%;
      margin: 0;
      ${({ home }) =>
        home &&
        `
      flex: 0 1 650px;
      `}
    }
  `}
`;

const HomeLogo = styled(GatsbyImage)`
  z-index: 1;
`;

export default Header;
