import React, { createContext, useContext, useLayoutEffect, useState } from 'react';
import { getSize } from './utils';
const SiteContext = createContext();

const SiteContextProvider = ({ home, children, data }) => {
  const [viewport, setViewport] = useState({ width: 0, height: 0 });
  useLayoutEffect(() => {
    setViewport(getSize());
  }, []);

  return <SiteContext.Provider value={{ home, ...data, viewport }}>{children}</SiteContext.Provider>;
};

const useSiteContext = () => useContext(SiteContext);

export { SiteContextProvider, SiteContext };
export default useSiteContext;
