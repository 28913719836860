import React from 'react';
import styled from 'styled-components';

import bg from '../../static/bacop_rainbow.svg';
import useSiteContext from './SiteContext';
import { media } from './theme';

const BgPattern = () => {
  const { viewport } = useSiteContext();

  return (
    <>
      <Pattern className="pattern left" viewWidth={viewport.width}>
        <img src={bg} />
        <img src={bg} />
        <img src={bg} />
        <img src={bg} />
      </Pattern>
      <Pattern className="pattern right" viewWidth={viewport.width}>
        <img src={bg} />
        <img src={bg} />
        <img src={bg} />
        <img src={bg} />
      </Pattern>
    </>
  );
};

const Pattern = styled.div`
  display: none;
  ${media.break`
    display: block;
  `}
  overflow: hidden;
  position: absolute;
  top: 10%;
  width: 700px;
  width: ${({ viewWidth }) => ((viewWidth - 800) / 2) * 3}px;
  height: 90%;
  &.left {
    left: 0;
    transform: translateX(-40%);
  }
  &.right {
    right: 0;
    transform: translateX(40%) rotateY(180deg);
  }
  img {
    width: 65%;

    display: block;
    position: relative;
  }
  img:nth-child(odd) {
    transform: rotateY(180deg);
    right: 0;
  }
  img:nth-child(even) {
    margin-left: auto;
  }
`;

export default BgPattern;
